import { useEffect, useRef, useState } from 'react';
import { typeText } from 'utilities/functions';
import styles from './styles.module.scss';

const Home = () => {
  const titleRef = useRef(null);
  const [title, setTitle] = useState('');
  useEffect(() => {
    if (titleRef) {
      typeText('Bihan Chakraborty', 100, (char) => setTitle((prev) => prev + char));
    }
  }, [titleRef]);
  return (
    <div id='hero' className={styles.heroContainer}>
      <div ref={titleRef} className={styles.heroTitle}>
        {title}
        <span>&nbsp;</span>
      </div>
      <div className={styles.heroDescription}>
        Software Engineer passionate about creating impactful experiences. 👨‍💻
        <br />
        Turning complex problems into elegant, user-friendly solutions through clean code and scalable infrastructure planning that grow with your needs. 🌳
      </div>
    </div>
  );
};

export default Home;
