import styles from './styles.module.scss';

const Timeline = () => {
  return (
    <ul className={styles.timelineEvents}>
      <li className={`${styles.timelineEventYears2}`}>
        <h2>Jun 20 - Sep 20</h2>
        <h3>Kalpkriti</h3>
        <h4>Fullstack Developer Intern</h4>
      </li>
      <li className={styles.timelineEventYears2}>
        <h2>Feb 21 - Mar - 21</h2>
        <h3>Mavoix</h3>
        <h4>Fullstack Developer Intern</h4>
      </li>
      <li className={styles.timelineEventYears2}>
        <h2>Dec 20 - May 22</h2>
        <h3>Meliorist Developers</h3>
        <h4>Fullstack Developer Intern</h4>
      </li>
      <li className={styles.timelineEventYears2}>
        <h2>Jul 22 - Mar 25</h2>
        <h3>Adpushup</h3>
        <h4>Software Engineer</h4>
      </li>
      <li className={styles.timelineEventYears2}>
        <h2>Apr 25 - Present</h2>
        <h3>Sprinto</h3>
        <h4>Software Engineer</h4>
      </li>
    </ul>
  );
};

export default Timeline;
