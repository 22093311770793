import styles from './styles.module.scss';
import skills from './data';

const Skills = () => {
  return (
    <>
      <h2 id='skills' className='section-title'>
        Skills
      </h2>
      <div className={styles.skillsContainer}>
        <div className={styles.skillSection}>
          <h3>Languages</h3>
          <div className={styles.skillItems}>
            {skills.languages.map((skill, index) => (
              <span key={index} className={styles.skillItem}>{skill}</span>
            ))}
          </div>
        </div>
        
        <div className={styles.skillSection}>
          <h3>Tools & Frameworks</h3>
          <div className={styles.skillItems}>
            {skills.tools.map((skill, index) => (
              <span key={index} className={styles.skillItem}>{skill}</span>
            ))}
          </div>
        </div>
        
        <div className={styles.skillSection}>
          <h3>Databases & Cloud</h3>
          <div className={styles.skillItems}>
            {skills.databases.map((skill, index) => (
              <span key={index} className={styles.skillItem}>{skill}</span>
            ))}
          </div>
        </div>
        
        <div className={styles.skillSection}>
          <h3>Devops & Version Control</h3>
          <div className={styles.skillItems}>
            {skills.devops.map((skill, index) => (
              <span key={index} className={styles.skillItem}>{skill}</span>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Skills;
