const projects = [
  {
    image: 'https://images.unsplash.com/photo-1489875347897-49f64b51c1f8?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    title: 'In-Memory DB similar to Redis',
    description:
      'The database handles multiple concurrent connections on a single thread using an epoll-based event loop. It supports CRUD operations with optional timer-based expiration, manages memory efficiently through LRU eviction, and ensures durability with Write-Ahead Logging for crash recovery.',
    tags: ['Go', 'Operating Systems', 'Networking', 'Databases'],
    liveDemoLink: '',
    videoDemoLink: '',
    codeLink: 'https://github.com/Bihan001/in-memory-db',
  },
  {
    image: 'https://raw.githubusercontent.com/Bihan001/world-graph/refs/heads/master/docs/screenshot.png',
    title: 'World Graph - Map Routing Application',
    description: 'The application utilizes graph algorithms like Bidirectional Dijkstra, A*, and Contraction Hierarchies to compute the shortest path between two map points, with visualizations of scanned roads to compare algorithm performance.',
    liveDemoLink: 'https://world-graph.onrender.com',
    videoDemoLink: '',
    tags: ['Node.js', 'React', 'Typescript', 'Graphs', 'Docker'],
    codeLink: 'https://github.com/Bihan001/world-graph',
  },
  {
    image: 'https://raw.githubusercontent.com/Bihan001/Kaisen-OS/refs/heads/master/kaisenos.webp',
    title: 'Kaisen OS',
    description:
      'A web application that mimics the working of an Operating System. It has a file system, authentication, themes and wallpapers, etc. It also has a terminal that can be used to run commands.',
    tags: ['react', 'nodejs', 'typescript', 'firebase', 'mongodb', 'websockets'],
    liveDemoLink: '',
    videoDemoLink: 'https://www.youtube.com/watch?v=TZiNBGn5v-g',
    codeLink: 'https://github.com/Bihan001/Kaisen-OS',
  }
];

export default projects;
